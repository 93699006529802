import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card, Dropdown } from "react-bootstrap";
import apiAxios from "../../../config/ApiAxios";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import { handleGraficosParams } from "../../../utils/handleGraficosParams";

const ACobrarTorta = () => {
  const [indexTipoDato, setIndexTipoDato] = useState(0);

  const [series, setSeries] = useState([
    {
      name: "Capital",
      data: [44, 55, 57, 55],
    },
    {
      name: "Interés",
      data: [76, 85, 101, 44],
    },
    {
      name: "Punitorio",
      data: [12, 41, 36, 55],
    },
  ]);

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const apiResponse = await apiAxios.get("/reportes/global");

        if (apiResponse?.status === 200) {
          // console.log("RESPONSE 2 ", apiResponse);
          setSeries([
            {
              name: "Capital",
              data: [
                apiResponse?.data?.cobrado?.total_capital,
                apiResponse?.data?.a_cobrar_en_termino?.total_capital,
                apiResponse?.data?.en_mora?.total_capital,
                apiResponse?.data?.prescripto?.total_capital,
                apiResponse?.data?.prestado?.total_capital,
              ],
            },
            {
              name: "Interés",
              data: [
                apiResponse?.data?.cobrado?.total_interes,
                apiResponse?.data?.a_cobrar_en_termino?.total_interes,
                apiResponse?.data?.en_mora?.total_interes,
                apiResponse?.data?.prescripto?.total_interes,
                apiResponse?.data?.prestado?.total_interes,
              ],
            },
            {
              name: "Punitorio",
              data: [
                apiResponse?.data?.cobrado?.total_interes_punitorio,
                apiResponse?.data?.a_cobrar_en_termino?.total_interes_punitorio,
                apiResponse?.data?.en_mora?.total_interes_punitorio,
                apiResponse?.data?.prescripto?.total_interes_punitorio,
                apiResponse?.data?.prestado?.total_interes_punitorio,
              ],
            },
          ]);
        }
      } catch (e) {
        console.log(e);
      }
    };

    obtenerCreditos();

    return () => {
      setSeries([{}]);
    };
  }, []);

  const colors = [
    {
      backgroundColor: ["#008FFB", "#0019FB", "#00F8FB", "#5D00FB", "#925BF0"],
    },
    {
      backgroundColor: ["#00e396", "#05A06B", "#90F8C9", "#00430E", "#B3E174"],
    },
    {
      backgroundColor: ["#feb019", "#FE1972", "#BE974D", "#810508", "#EF7B7E"],
    },
  ];

  const options = {
    chart: {
      width: "100%",
      height: "100%",
      type: "pie",
    },

    labels: ["Cobrado", "A Término", "En mora", "Prescripto", "Prestado"],
    tooltip: {
      y: {
        formatter: function (val) {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(val);
        },
      },
    },
    colors: colors[indexTipoDato].backgroundColor,

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const query = useQuery();
  const history = useHistory();

  const ocultarGrafico = query.get("ocultarGrafico");

  const ocultarGraficoArray = ocultarGrafico
    ? ocultarGrafico.replace(/[[\]]/g, "").split(",").map(Number)
    : [];

  // console.log("SERIES ", series);

  return (
    <>
      <style>
        {`
          #a-cobrar-dropdown::before {
            content: '';
            height: 10px;
            width: 10px;
            background-color: ${colors[indexTipoDato].backgroundColor[0]};
            display: inline-block;
            margin-right: 5px;
          }
        `}
      </style>
      <Card
        className="column-card"
        style={{
          display: ocultarGraficoArray.includes(2) ? "none" : "block",
        }}
      >
        <Card.Body>
          <Card.Title className="d-flex justify-content-between">
            <span>
              Por Tipo y Estado del Capital (Torta){" "}
              <button
                title="Ocultar"
                className="btn btn-light ms-2"
                onClick={() =>
                  handleGraficosParams(2, ocultarGraficoArray, history)
                }
              >
                <i class="bi bi-eye"></i>
              </button>
              <a
                title="Muestra la distribución global de los montos asociados a Capital, Interés y Punitorio, considerando todos los movimientos del periodo sin filtro de fechas."
                className="btn btn-light ms-2"
                disabled
              >
                <i class="bi bi-info-circle"></i>
              </a>
            </span>

            <Dropdown>
              <Dropdown.Toggle variant="default" id="a-cobrar-dropdown">
                {indexTipoDato === 0
                  ? "Capital"
                  : indexTipoDato === 1
                  ? "Interés"
                  : "Punitorio"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={() => setIndexTipoDato(0)}>
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#008ffb",
                      }}
                    ></div>
                    Capital
                  </div>
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => setIndexTipoDato(1)}>
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#00e396",
                      }}
                    ></div>
                    Interés
                  </div>
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => setIndexTipoDato(2)}>
                  <div className="d-flex align-items-center gap-2">
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#feb019",
                      }}
                    ></div>
                    Punitorio
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Card.Title>
          <div className="mx-auto">
            <Chart
              options={options}
              series={series[indexTipoDato].data}
              type="pie"
              height={320}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ACobrarTorta;
