import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import apiAxios from "../../../config/ApiAxios";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../utils/useQuery";
import { handleGraficosParams } from "../../../utils/handleGraficosParams";
import DatePicker, { registerLocale } from "react-datepicker";
import { parseDate } from "../../../utils/dateFormatter";
import { es } from "date-fns/locale";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { DesdeButton, HastaButon } from "../DesdeYHastaButtons";

registerLocale("es", es);

const ACobrarColumnas = () => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
      width: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Cobrado", "A Término", "En mora", "Prescripto", "Prestado"],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(val);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(val);
        },
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const [series, setSeries] = useState([{}]);

  const [startDate, setStartDate] = useState(new Date("2023/02/08"));
  const [endDate, setEndDate] = useState(new Date());
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const fechaInicio = searchParams.get("fecha-inicio");
    const fechaFinal = searchParams.get("fecha-final");

    if (fechaInicio) {
      const parsedStartDate = parseDate(fechaInicio);
      setStartDate(parsedStartDate);
    }

    if (fechaFinal) {
      const parsedEndDate = parseDate(fechaFinal);
      setEndDate(parsedEndDate);
    }
  }, [location.search]);

  const startDateYear = startDate.getFullYear();
  const startDateMonth = String(startDate.getMonth() + 1).padStart(2, "0");
  const startDateDay = String(startDate.getDate()).padStart(2, "0");

  const endDateYear = endDate.getFullYear();
  const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0");
  const endDateDay = String(endDate.getDate()).padStart(2, "0");

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const apiResponse = await apiAxios.get(
          `/reportes/global?fecha_desde=${startDateYear}-${startDateMonth}-${startDateDay}&fecha_hasta=${endDateYear}-${endDateMonth}-${endDateDay}`
        );

        if (apiResponse?.status === 200) {
          setSeries([
            {
              name: "Capital",
              data: [
                apiResponse?.data?.cobrado?.total_capital,
                apiResponse?.data?.a_cobrar_en_termino?.total_capital,
                apiResponse?.data?.en_mora?.total_capital,
                apiResponse?.data?.prescripto?.total_capital,
                apiResponse?.data?.prestado?.total_capital,
              ],
            },
            {
              name: "Interés",
              data: [
                apiResponse?.data?.cobrado?.total_interes,
                apiResponse?.data?.a_cobrar_en_termino?.total_interes,
                apiResponse?.data?.en_mora?.total_interes,
                apiResponse?.data?.prescripto?.total_interes,
                apiResponse?.data?.prestado?.total_interes,
              ],
            },
            {
              name: "Punitorio",
              data: [
                apiResponse?.data?.cobrado?.total_interes_punitorio,
                apiResponse?.data?.a_cobrar_en_termino?.total_interes_punitorio,
                apiResponse?.data?.en_mora?.total_interes_punitorio,
                apiResponse?.data?.prescripto?.total_interes_punitorio,
                apiResponse?.data?.prestado?.total_interes_punitorio,
              ],
            },
          ]);
        }
      } catch (e) {
        console.log(e);
      }
    };

    obtenerCreditos();

    return () => {
      setSeries([{}]);
    };
  }, [
    endDateDay,
    endDateMonth,
    endDateYear,
    startDateDay,
    startDateMonth,
    startDateYear,
  ]);

  const query = useQuery();
  const history = useHistory();

  const ocultarGrafico = query.get("ocultarGrafico");

  const ocultarGraficoArray = ocultarGrafico
    ? ocultarGrafico.replace(/[[\]]/g, "").split(",").map(Number)
    : [];

  return (
    <div
      className="column-card"
      style={{
        display: ocultarGraficoArray.includes(1) ? "none" : "block",
      }}
    >
      <div className="px-2 py-2 border border-bottom-0 d-flex align-items-center gap-1">
        <DatePicker
          locale={"es"}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={"dd/MM/yyyy"}
          customInput={<DesdeButton className="" />}
        />
        <i class="bi bi-arrow-right"></i>
        <DatePicker
          locale={"es"}
          selectsEnd
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat={"dd/MM/yyyy"}
          customInput={<HastaButon className="me-3" />}
        />
      </div>
      <Card>
        <Card.Body>
          <Card.Title>
            Por Tipo y Estado del Capital (Columnas)
            <button
              title="Ocultar"
              className="btn btn-light ms-2"
              onClick={() =>
                handleGraficosParams(1, ocultarGraficoArray, history)
              }
            >
              <i class="bi bi-eye"></i>
            </button>
            <a
                title="Permite analizar a nivel global la ganancias y perdidas de un plazo determinado.
                Seleccionando un periodo de tiempo filtra lo 'prestado', y partir de ello muestra únicamente lo pagado, y lo pendiente (a término, en mora, y prescripto)
                correspondiente a los créditos otorgados
                "
                className="btn btn-light ms-2"
                disabled
              >
                <i class="bi bi-info-circle"></i>
              </a>
          </Card.Title>
          <Chart options={options} series={series} type="bar" height={320} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default ACobrarColumnas;
