import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {  Card } from "react-bootstrap";
import { es } from "date-fns/locale";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import { handleGraficosParams } from "../../../utils/handleGraficosParams";
import { DesdeButton, HastaButon } from "../DesdeYHastaButtons";
import { useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import apiAxios from "../../../config/ApiAxios";
import "react-datepicker/dist/react-datepicker.css";
import { parseDate } from "../../../utils/dateFormatter";

registerLocale("es", es);

const PagosPorMontos = () => {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  const generarCategorias = (startYear, startMonth, endYear, endMonth) => {
    const meses = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];

    let categories = [];

    startMonth -= 1;
    endMonth -= 1;

    for (let year = startYear; year <= endYear; year++) {
      let firstMonth = year === startYear ? startMonth : 0;
      let lastMonth = year === endYear ? endMonth : 11;

      for (let mes = firstMonth; mes <= lastMonth; mes++) {
        if (mes === firstMonth && year === startYear) {
          categories.push(`${meses[mes]} ${year}`);
        } else if (mes === lastMonth && year === endYear) {
          categories.push(`${meses[mes]} ${year}`);
        } else {
          categories.push(meses[mes]);
        }
      }
    }

    return categories;
  };

  const transformarDatos = (data, startYear, startMonth, endYear, endMonth) => {
    const totalMeses = (endYear - startYear) * 12 + (endMonth - startMonth + 1);
    const pagoTotalData = Array(totalMeses).fill(0);
    const pagoParcialData = Array(totalMeses).fill(0);
    const pagoMayorACuotaData = Array(totalMeses).fill(0);

    const indexMes = (anio, mes) => {
      const yearDiff = anio - startYear;
      const monthDiff = mes - startMonth;
      return yearDiff * 12 + monthDiff;
    };

    data.TOTAL.forEach((entry) => {
      if (
        entry.anio >= startYear &&
        entry.anio <= endYear &&
        (entry.anio > startYear || entry.mes >= startMonth) &&
        (entry.anio < endYear || entry.mes <= endMonth)
      ) {
        const index = indexMes(entry.anio, entry.mes);
        pagoTotalData[index] = entry.cantidad;
      }
    });

    data.PARCIAL.forEach((entry) => {
      if (
        entry.anio >= startYear &&
        entry.anio <= endYear &&
        (entry.anio > startYear || entry.mes >= startMonth) &&
        (entry.anio < endYear || entry.mes <= endMonth)
      ) {
        const index = indexMes(entry.anio, entry.mes);
        pagoParcialData[index] = entry.cantidad;
      }
    });

    data.EXCEDENTE.forEach((entry) => {
      if (
        entry.anio >= startYear &&
        entry.anio <= endYear &&
        (entry.anio > startYear || entry.mes >= startMonth) &&
        (entry.anio < endYear || entry.mes <= endMonth)
      ) {
        const index = indexMes(entry.anio, entry.mes);
        pagoMayorACuotaData[index] = entry.cantidad;
      }
    });

    return {
      pagoTotal: pagoTotalData,
      pagoParcial: pagoParcialData,
      pagoMayorACuota: pagoMayorACuotaData,
    };
  };

  const [startDate, setStartDate] = useState(new Date("2023/02/08"));
  const [endDate, setEndDate] = useState(new Date());
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const fechaInicio = searchParams.get("fecha-inicio");
    const fechaFinal = searchParams.get("fecha-final");

    if (fechaInicio) {
      const parsedStartDate = parseDate(fechaInicio);
      setStartDate(parsedStartDate);
    }

    if (fechaFinal) {
      const parsedEndDate = parseDate(fechaFinal);
      setEndDate(parsedEndDate);
    }
  }, [location.search]);

  const startDateYear = startDate.getFullYear();
  const startDateMonth = String(startDate.getMonth() + 1).padStart(2, "0");
  const startDateDay = String(startDate.getDate()).padStart(2, "0");

  const endDateYear = endDate.getFullYear();
  const endDateMonth = String(endDate.getMonth() + 1).padStart(2, "0");
  const endDateDay = String(endDate.getDate()).padStart(2, "0");

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const apiResponse = await apiAxios.get(
          `/reportes/global/pagos/por-estado?fecha_desde=${startDateYear}-${startDateMonth}-${startDateDay}&fecha_hasta=${endDateYear}-${endDateMonth}-${endDateDay}&agrupar_por[]=estado`
        );

        if (apiResponse?.status === 200) {
          const categorias = generarCategorias(
            startDateYear,
            startDateMonth,
            endDateYear,
            endDateMonth
          );

          setCategories(categorias);
          const datosTransformados = transformarDatos(
            apiResponse?.data,
            startDateYear,
            startDateMonth,
            endDateYear,
            endDateMonth
          );

          setSeries([
            {
              name: "Totales",
              data: datosTransformados.pagoTotal,
            },
            {
              name: "Parciales",
              data: datosTransformados.pagoParcial,
            },
            {
              name: "Mayores a la cuota",
              data: datosTransformados.pagoMayorACuota,
            },
          ]);
        }
      } catch (e) {
        console.log(e);
      }
    };

    obtenerCreditos();
  }, [
    endDateDay,
    endDateMonth,
    endDateYear,
    startDateDay,
    startDateMonth,
    startDateYear,
  ]);

  const options = {
    chart: {
      height: 350,
      type: "line",
    },
    stroke: {
      width: [4, 4, 4],
    },
    xaxis: {
      categories: categories,
    },
  };

  const query = useQuery();
  const history = useHistory();

  const ocultarGrafico = query.get("ocultarGrafico");

  const ocultarGraficoArray = ocultarGrafico
    ? ocultarGrafico.replace(/[[\]]/g, "").split(",").map(Number)
    : [];
  return (
    <div
      style={{
        display: ocultarGraficoArray.includes(4) ? "none" : "block",
      }}
    >
      <div className="px-2 py-2 border border-bottom-0 d-flex align-items-center gap-1">
        <DatePicker
          locale={"es"}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={"dd/MM/yyyy"}
          customInput={<DesdeButton className="" />}
        />
        <i class="bi bi-arrow-right"></i>
        <DatePicker
          locale={"es"}
          selectsEnd
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat={"dd/MM/yyyy"}
          customInput={<HastaButon className="me-3" />}
        />
      </div>
      <Card>
        <Card.Body>
          <Card.Title>
            Pagos por monto{" "}
            <button
              title="Ocultar"
              className="btn btn-light ms-2"
              onClick={() =>
                handleGraficosParams(4, ocultarGraficoArray, history)
              }
            >
              <i class="bi bi-eye"></i>
            </button>
             <a
                title="Muestra la evolución mensual de los pagos realizados, distribuidos en tres categorías: Totales, Parciales y Mayores a la cuota.
                Cada punto en la línea representa la cantidad de pagos de cada tipo realizados en un mes/año específico."
                className="btn btn-light ms-2"
                disabled
              >
                <i class="bi bi-info-circle"></i>
              </a>
          </Card.Title>
          <Chart options={options} series={series} height={450} />
        </Card.Body>
      </Card>
    </div>
  );
};

export default PagosPorMontos;
