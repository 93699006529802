import { format } from "date-fns";
import { useState, useEffect } from "react";
import { Spinner } from "../../../../shared";

const TablaProcesos = ({ data, onClick, onChange, sort, setSort }) => {
  const [seleccion, setSeleccion] = useState(null);

  useEffect(() => {
    const persona_id = localStorage.getItem("reportes-atrasos-persona");
    if (persona_id) {
      setSeleccion(persona_id);
    }
  }, []);
  return (
    <table
      className="table table-hover align-middle"
      style={{
        overflowY: "auto",
      }}
    >
      <thead className="border-0 sticky">
        <tr
          className="border tr_sticky"
          style={{
            borderRadius: "20px",
            background: "rgba(217, 217, 217, 0.5)",
          }}
        >
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Acciones
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Proceso
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Fecha
              <div
                className={
                  sort == "-procesos.created_at" || sort == "procesos.created_at"
                    ? ""
                    : "sort-arrow"
                }
              >
                {sort == "procesos.created_at" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort:
                        sort == "-procesos.created_at" ? "procesos.created_at" : "-procesos.created_at",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Cliente
              <div
                className={
                  sort == "-apellido" || sort == "apellido" ? "" : "sort-arrow"
                }
              >
                {sort == "apellido" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort: sort == "-apellido" ? "apellido" : "-apellido",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Telefono
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Al día
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Atrasados
            </div>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Último pago
              <div
                className={
                  sort == "-ultimo_pago.created_at" ||
                  sort == "ultimo_pago.created_at"
                    ? ""
                    : "sort-arrow"
                }
              >
                {sort == "ultimo_pago.created_at" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort:
                        sort == "-ultimo_pago.created_at"
                          ? "ultimo_pago.created_at"
                          : "-ultimo_pago.created_at",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <label
              className="btn fs-5 fw-bold border-0 p-1 d-flex justify-content-between text-nowrap sort"
              style={{ borderRadius: "10px" }}
            >
              Ultima tarea
              <div
                className={
                  sort == "-procesos.ultimaTarea.fecha_vencimiento" ||
                  sort == "procesos.ultimaTarea.fecha_vencimiento"
                    ? ""
                    : "sort-arrow"
                }
              >
                {sort == "procesos.ultimaTarea.fecha_vencimiento" ? (
                  <i class="bi bi-arrow-down"></i>
                ) : (
                  <i class="bi bi-arrow-up"></i>
                )}
                <button
                  className="d-none"
                  name="sort"
                  onClick={(event) =>
                    onClick(event, {
                      sort:
                        sort == "-procesos.ultimaTarea.fecha_vencimiento"
                          ? "procesos.ultimaTarea.fecha_vencimiento"
                          : "-procesos.ultimaTarea.fecha_vencimiento",
                    })
                  }
                ></button>
              </div>
            </label>
          </th>
          <th className="border-0 fs-5 p-1">
            <div
              className="border-0 p-1 text-nowrap"
              style={{ borderRadius: "10px" }}
            >
              Estado
            </div>
          </th>
        </tr>
      </thead>

      {!data ? (
        <td className="my-0 border-0 p-0" colSpan={10}>
          <Spinner />
        </td>
      ) : (
        <tbody className="border-0">
          {data.map((d, index) => (
            <tr
              className="border border-end-0 border-start-0"
              style={{
                background: seleccion == d.id ? "#67a9ff" : "",
              }}
              onClick={() => {
                //setSeleccion(d.persona.id);
                //localStorage.setItem("reportes-atrasos-persona", d.persona.id);
              }}
            >
              <td className="border-0">
                <div className="d-flex">
                  <label
                    className="btn btn-primary fs-6 d-flex  text-nowrap me-2"
                    title="editar"
                  >
                    <i class="bi bi-pencil-square"></i>
                    <button
                      className="d-none"
                      onClick={() => {
                        window.location.href =
                          "/gestion-mora/reportes-atrasos/editar/" + d.id;
                      }}
                    ></button>
                  </label>

                  <label
                    className="btn btn-danger fs-6 d-flex text-nowrap"
                    title="eliminar"
                  >
                    <i class="bi bi-trash"></i>
                    <button
                      className="d-none"
                      name="eliminar-proceso"
                      onClick={(event) => onClick(event, d)}
                    ></button>
                  </label>
                </div>
              </td>
              <td className="border-0">{d.procesos[0].id}</td>
              <td className="border-0">
                {format(
                  new Date(d.procesos[0].created_at),
                  "dd/MM/yyyy"
                ).toString()}
              </td>
              <td className="border-0">
                <a
                  className="text-dark"
                  href={`/clientes/ver/${d.id}`}
                  target="_blank"
                >
                  {d.apellido + " " + d.nombre}
                </a>
              </td>
              <td className="border-0">
                {d.contactos.map((c, index) => (
                  <p className="text-nowrap">
                    {c.nro_telefono_prefix + "-" + c.nro_telefono}
                  </p>
                ))}
              </td>
              <td className="border-0">
                {d.deudaAcumulada.map((credito) => {
                  if (credito.deuda == 0) {
                    return (
                      <a
                        className="d-block "
                        href={`/creditos/ver/${credito.credito_id}`}
                        target="_blank"
                      >
                        {credito.credito_id}
                      </a>
                    );
                  }
                })}
              </td>
              <td className="border-0">
                {d.deudaAcumulada.map((credito) => {
                  if (credito.deuda > 0) {
                    return (
                      <a
                        className="d-block "
                        href={`/creditos/ver/${credito.credito_id}`}
                        target="_blank"
                      >
                        {credito.credito_id}
                      </a>
                    );
                  }
                })}
              </td>
              <td className="border-0">
                {d.ultimoPago.map((p) => (
                  <p className="d-block ">
                    {format(new Date(p.created_at), "dd/MM/yyyy").toString()}
                  </p>
                ))}
              </td>
              <td className="border-0">
                {d.procesos[0].ultima_tarea.length > 0
                  ? format(
                      new Date(d.procesos[0].ultima_tarea[0].fecha_vencimiento),
                      "dd/MM/yyyy"
                    ).toString()
                  : "Sin tareas"}
              </td>
              <td className="border-0">{d.procesos[0].estado}</td>
            </tr>
          ))}
        </tbody>
      )}
      <tfoot></tfoot>
    </table>
  );
};

export default TablaProcesos;
