import { useContext, useEffect, useState } from "react";
import Calendario from "./Calendario";
import { AuthContext, clienteAxios } from "../../../shared";
import apiAxios from "../../../../config/ApiAxios";
import { format } from "date-fns";
import Modal from "../../../../module/modal/Modal";
import Tarea from "./modals/Tarea";
import {
  generateDateRange,
  getFirstDayOfMonth,
  getFirstDayOfWeek,
  getLastDayOfMonth,
  getLastDayOfWeek,
} from "../../../../utils/formatter";
import { ApiRestPost } from "../../../../Helpers/ApiRestPost";
import AlertSuccess from "../../../CustomAlerts/AlertSuccess";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const CalendarioController = () => {
  const { persona_id } = useParams();
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const [usuarios, setUsuarios] = useState([]);
  const [data, setData] = useState(null);
  const [tareas, setTareas] = useState(null);
  const [params, setParams] = useState({
    ["filter[intervalo_fecha_vencimiento]"]: `${getFirstDayOfWeek()},${getLastDayOfWeek()}`,
    ["filter[estado]"]: "pendiente",
    ["filter[cliente.id]"]: persona_id,
    per_page: 100,
  });
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("agenda");
  const [loading, setLoading] = useState(false);
  const [seguimiento, setSeguimiento] = useState({
    fecha_vencimiento: new Date(),
    comentarios: "",
  });

  const [formT, setFormT] = useState({
    id: null,
    index: null,
    usuario_id: usuario.id,

    estado: "pendiente",
    prioridad: "baja",
    resultado: null,
    tipo_id: null,
    tipo: null,
    subTipo: "",
    fecha_vencimiento: format(new Date(), "Y-MM-dd").toString(),
    created_at: format(new Date(), "Y-MM-dd").toString(),
    hora_contacto: "",
    persona_id: null,
    ultimo_seguimiento: [],

    cliente: null,
    usuario_asignado: {
      name: usuario.nombre,
      email: "",
    },
    asignado_id: usuario.id,
    asunto: "",
    descripcion: "",
  });

  const navigateCalendar = (e) => {
    setDate(e);

    switch (view) {
      case "month":
        {
          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${getFirstDayOfMonth(
              e
            )},${getLastDayOfMonth(e)}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, "month");
        }
        break;
      case "day":
        {
          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${format(
              e,
              "yyyy-MM-dd"
            ).toString()},${format(e, "yyyy-MM-dd").toString()}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, "day");
        }
        break;
      case "week":
        {
          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${getFirstDayOfWeek(
              e
            )},${getLastDayOfWeek(e)}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, view);
        }
        break;
      case "agenda":
        {
          let fecha = e;
          let { start, end } = generateDateRange(fecha, fecha.getDate());
          start = format(start, "yyyy-MM-dd").toString();
          end = format(end, "yyyy-MM-dd").toString();

          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${start},${end}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, "agenda");
        }
        break;
    }
  };

  const viewCalendar = (e) => {
    setDate(new Date());
    setView(e);

    switch (e) {
      case "month":
        {
          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${getFirstDayOfMonth()},${getLastDayOfMonth()}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, e);
        }
        break;
      case "day":
        {
          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${format(
              new Date(),
              "yyyy-MM-dd"
            ).toString()},${format(new Date(), "yyyy-MM-dd").toString()}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, e);
        }
        break;
      case "week":
        {
          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${getFirstDayOfWeek()},${getLastDayOfWeek()}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, e);
        }
        break;
      case "agenda":
        {
          let fecha = new Date();
          let { start, end } = generateDateRange(fecha, fecha.getDate());
          start = format(start, "yyyy-MM-dd").toString();
          end = format(end, "yyyy-MM-dd").toString();

          let filter = {
            ...params,
            ["filter[intervalo_fecha_vencimiento]"]: `${start},${end}`,
            per_page: 100,
          };
          setParams(filter);
          fetch(filter, e);
        }
        break;
    }
  };

  const onClick = async (e, value) => {
    switch (e.target.name) {
      case "asunto":
        {
          let filter = {
            ...params,
            ["filter[asunto]"]: e.target.value,
          };

          setParams(filter);
          fetch(filter);
        }
        break;
      case "tarea":
        {
          setFormT({
            id: value.id,
            index: null,
            usuario_id: value.usuario_id,

            estado: value.estado,
            prioridad: value.prioridad,
            resultado: value.resultado,
            tipo_id: value.tipo_id,
            tipo: value.tipo.tipo + " | " + value.tipo.subtipo,
            subTipo: "",
            fecha_vencimiento: format(
              new Date(value.fecha_vencimiento),
              "Y-MM-dd"
            ).toString(),
            created_at: format(
              new Date(value.created_at),
              "Y-MM-dd"
            ).toString(),
            hora_contacto: value.hora_contacto,
            persona_id: value.persona_id,
            ultimo_seguimiento: value.ultimo_seguimiento,
            cliente: value.cliente,
            usuario_asignado: value.usuario_asignado,
            asignado_id: value.asignado_id,
            asunto: value.asunto,
            descripcion: value.descripcion,
          });
        }
        break;
      case "actualizar-tarea-exitoso":
        {
          Swal.fire({
            title: "¿Quiere marcar la tarea como terminada?",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Finalizar",
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              let formData = new FormData();
              formData.append("_method", "PUT");
              formData.append("estado", "resuelto");

              const response = await ApiRestPost({
                url: "/tareas/" + value.id,
                setLoading: setLoading,
                form: formData,
              });

              if (response.status == 200 || response.status == 201) {
                Swal.fire("Tarea finalizada!", "", "success");
                fetch(params);
              }
            }
          });
        }
        break;
      case "actualizar-tarea-fracaso":
        {
          Swal.fire({
            title: "¿Quiere marcar la tarea como descartada?",
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Descartar",
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              let formData = new FormData();
              formData.append("_method", "PUT");
              formData.append("estado", "descartada");

              const response = await ApiRestPost({
                url: "/tareas/" + value.id,
                setLoading: setLoading,
                form: formData,
              });

              if (response.status == 200 || response.status == 201) {
                Swal.fire("Tarea descartada!", "", "success");
                fetch(params);
              }
            }
          });
        }
        break;
      case "actualizar-tarea":
        {
          if(formT.resultado !== null){
            let formTarea = new FormData();
            formTarea.append("_method", "PUT");
            formTarea.append("estado", "resuelto");
            formTarea.append("resultado", formT.resultado == "Exito" ? '1' : formT.resultado == "Fracaso" ? "0" : null);

            const response = await ApiRestPost({
              url: "/tareas/" + formT.id,
              setLoading: setLoading,
              form: formTarea,
            });

            if (response.status == 200 || response.status == 201) {
              Swal.fire("Tarea resuelta!", "", "success");
              fetch(params);
            }
          }

          

          if(
            seguimiento.comentarios.trim() == ""
          ){
            return "";
          }

          let formData = new FormData();
          formData.append("tarea_id", formT.id);
          formData.append("fecha_vencimiento", seguimiento.fecha_vencimiento);
          formData.append("comentarios", seguimiento.comentarios);

          const response = await ApiRestPost({
            url: "/tareas/" + formT.id + "/seguimientos",
            setLoading: setLoading,
            form: formData,
          });

          if (response.status == 200 || response.status == 201) {
            Swal.fire("Seguimiento creado!", "", "success");
            fetch(params);
          }
        }
        break;
      default:
        break;
    }
  };

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "usuario":
        {
          let filter = {
            ...params,
            ["filter[usuario_asignado_id]"]: e.target.value,
          };

          setParams({
            ...filter,
          });

          fetch(filter);
        }
        break;

      case "estado":
        {
          let filter = {
            ...params,
            ["filter[estado]"]: e.target.value,
          };

          setParams({
            ...filter,
          });

          fetch(filter);
        }
        break;

      default:
        break;
    }
  };

  const fetch = async (set, view) => {
    setData([]);
    let params = {
      ...set,
    };
    const response = await apiAxios.get("/tareas", { params });
    viewTask(response.data, view);
  };

  const viewTask = (response, view) => {
    switch (view) {
      case "month":
        {
          setData(
            response.data.map((d, index) => {
              return {
                id: d.id,
                title: (
                  <label className="w-100 p-0 m-0 fs-6 bg-transparent cursor-pointer">
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Cliente: {d.cliente.nombre + " " + d.cliente.apellido}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asignado:{" "}
                      {d.usuario_asignado.name +
                        " " +
                        d.usuario_asignado.lastname}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asunto: {d.asunto}
                    </span>
                    {d.ultimo_seguimiento.length > 0 ? (
                      <span>
                        <span className="d-block" style={{ fontSize: "10px" }}>
                          Seguimiento: {d.ultimo_seguimiento[0].comentarios}
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                    <button
                      data-bs-toggle="modal"
                      data-bs-target={"#actualizar-tarea"}
                      className="d-none"
                      name="tarea"
                      onClick={(event) => onClick(event, d)}
                    ></button>
                  </label>
                ),
                start: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                end: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                priority: d.prioridad,
                status: d.estado,
              };
            })
          );
        }
        break;
      case "day":
        {
          setData(
            response.data.map((d, index) => {
              return {
                id: d.id,
                title: (
                  <label className="w-100 p-0 m-0 fs-6 bg-transparent cursor-pointer">
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asignado:{" "}
                      {d.usuario_asignado.name +
                        " " +
                        d.usuario_asignado.lastname}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asunto: {d.asunto}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Tarea{" "}
                    </span>
                    <textarea
                      className="form-control bg-white"
                      style={{ fontSize: "10px" }}
                      disabled
                    >
                      {d.descripcion}
                    </textarea>
                    <span className="d-block" style={{ fontSize: "12px" }}>
                      Estado: {d.estado}
                    </span>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target={"#actualizar-tarea"}
                      className="d-none"
                      name="tarea"
                      onClick={(event) => onClick(event, d)}
                    ></button>
                  </label>
                ),
                start: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                end: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                priority: d.prioridad,
                status: d.estado,
              };
            })
          );
        }
        break;
      case "week":
        {
          setData(
            response.data.map((d, index) => {
              return {
                id: d.id,
                title: (
                  <label className="w-100 p-0 m-0 fs-6 bg-transparent cursor-pointer">
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Cliente: {d.cliente.nombre + " " + d.cliente.apellido}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asignado:{" "}
                      {d.usuario_asignado.name +
                        " " +
                        d.usuario_asignado.lastname}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asunto: {d.asunto}
                    </span>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target={"#actualizar-tarea"}
                      className="d-none"
                      name="tarea"
                      onClick={(event) => onClick(event, d)}
                    ></button>
                  </label>
                ),
                start: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                end: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                priority: d.prioridad,
                status: d.estado,
              };
            })
          );
        }
        break;
      case "agenda":
        {
          setData(
            response.data.map((d, index) => {
              return {
                id: d.id,
                title: (
                  <label className="w-100 p-0 m-0 fs-6 bg-transparent">
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Cliente: {d.cliente.nombre + " " + d.cliente.apellido}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asunto: {d.asunto}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Tarea{" "}
                    </span>
                    <textarea
                      className="form-control bg-white"
                      style={{ fontSize: "10px" }}
                      disabled
                    >
                      {d.descripcion}
                    </textarea>
                    {d.ultimo_seguimiento.length > 0 ? (
                      <span>
                        <span className="d-block" style={{ fontSize: "10px" }}>
                          Ultimo seguimiento
                        </span>
                        <textarea
                          className="form-control bg-white"
                          style={{ fontSize: "10px" }}
                          disabled
                        >
                          {d.ultimo_seguimiento[0].comentarios}
                        </textarea>
                      </span>
                    ) : (
                      ""
                    )}
                    <span className="d-block" style={{ fontSize: "12px" }}>
                      Asignado:{" "}
                      {d.usuario_asignado.lastname +
                        " " +
                        d.usuario_asignado.name}
                    </span>
                    <span className="d-block" style={{ fontSize: "12px" }}>
                      Estado: {d.estado}
                    </span>
                    <div className="d-flex">
                      <label className="w-auto btn btn-primary d-block div-crear-proceso text-nowrap d-flex me-1  px-1 py-1 align-items-center">
                        <i
                          class="bi bi-pencil-square"
                          style={{ fontSize: "10px" }}
                        ></i>
                        <span
                          className="ms-1 span-crear-proceso"
                          style={{ fontSize: "12px" }}
                        >
                          Modificar
                        </span>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target={"#actualizar-tarea"}
                          className="d-none"
                          name="tarea"
                          onClick={(event) => onClick(event, d)}
                        ></button>
                      </label>
                      {d.estado === "pendiente" ? (
                        <label className="w-auto btn btn-success d-block div-crear-proceso text-nowrap d-flex me-1  px-1 py-1 align-items-center">
                          <i
                            class="bi bi-check-circle"
                            style={{ fontSize: "10px" }}
                          ></i>
                          <span
                            className="ms-1 span-crear-proceso"
                            style={{ fontSize: "12px" }}
                          >
                            Marcar como terminado
                          </span>
                          <button
                            className="d-none"
                            name="actualizar-tarea-exitoso"
                            onClick={(event) => onClick(event, d)}
                          ></button>
                        </label>
                      ) : null}

                      {d.estado === "resuelto" ? (
                        <label
                          className="w-auto btn btn-success"
                          style={{ fontSize: "12px" }}
                        >
                          <i
                            class="bi bi-check-circle me-1"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Terminado
                        </label>
                      ) : null}

                      {d.estado === "pendiente" ? (
                        <label className="w-auto btn btn-danger d-block div-crear-proceso text-nowrap d-flex me-1 px-1 py-1 align-items-center">
                          <i
                            class="bi bi-x-circle "
                            style={{ fontSize: "10px" }}
                          ></i>
                          <span
                            className="ms-1 span-crear-proceso"
                            style={{ fontSize: "12px" }}
                          >
                            Marcar como descartado
                          </span>
                          <button
                            className="d-none"
                            name="actualizar-tarea-fracaso"
                            onClick={(event) => onClick(event, d)}
                          ></button>
                        </label>
                      ) : null}

                      {d.estado === "descartada" ? (
                        <label
                          className="w-auto btn btn-danger"
                          style={{ fontSize: "12px" }}
                        >
                          <i
                            class="bi bi-x-circle me-1"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Descartado
                        </label>
                      ) : null}
                    </div>
                  </label>
                ),
                start: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                end: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                priority: d.prioridad,
                status: d.estado,
              };
            })
          );
        }
        break;
      default:
        {
          setData(
            response.data.map((d, index) => {
              return {
                id: d.id,
                title: (
                  <label className="w-100 p-0 m-0 fs-6 bg-transparent">
                    {" "}
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Cliente: {d.cliente.nombre + " " + d.cliente.apellido}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Asunto: {d.asunto}
                    </span>
                    <span className="d-block" style={{ fontSize: "10px" }}>
                      Tarea principal
                    </span>
                    <textarea
                      className="form-control bg-white"
                      style={{ fontSize: "10px" }}
                      disabled
                    >
                      {d.descripcion}
                    </textarea>
                    {d.ultimo_seguimiento.length > 0 ? (
                      <span>
                        <span className="d-block" style={{ fontSize: "10px" }}>
                          Ultimo seguimiento
                        </span>
                        <textarea
                          className="form-control bg-white"
                          style={{ fontSize: "10px" }}
                          disabled
                        >
                          {d.ultimo_seguimiento[0].comentarios}
                        </textarea>
                      </span>
                    ) : (
                      ""
                    )}
                    <span className="d-block" style={{ fontSize: "12px" }}>
                      Asignado:{" "}
                      {d.usuario_asignado.lastname +
                        " " +
                        d.usuario_asignado.name}
                    </span>
                    <span className="d-block" style={{ fontSize: "12px" }}>
                      Estado: {d.estado}
                    </span>
                    <div className="d-flex">
                      <label className="w-auto btn btn-primary d-block div-crear-proceso text-nowrap d-flex me-1  px-1 py-1 align-items-center">
                        <i
                          class="bi bi-pencil-square"
                          style={{ fontSize: "10px" }}
                        ></i>
                        <span
                          className="ms-1 span-crear-proceso"
                          style={{ fontSize: "12px" }}
                        >
                          Modificar
                        </span>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target={"#actualizar-tarea"}
                          className="d-none"
                          name="tarea"
                          onClick={(event) => onClick(event, d)}
                        ></button>
                      </label>
                      {d.estado === "pendiente" ? (
                        <label className="w-auto btn btn-success d-block div-crear-proceso text-nowrap d-flex me-1  px-1 py-1 align-items-center">
                          <i
                            class="bi bi-check-circle"
                            style={{ fontSize: "10px" }}
                          ></i>
                          <span
                            className="ms-1 span-crear-proceso"
                            style={{ fontSize: "12px" }}
                          >
                            Marcar como terminado
                          </span>
                          <button
                            className="d-none"
                            name="actualizar-tarea-exitoso"
                            onClick={(event) => onClick(event, d)}
                          ></button>
                        </label>
                      ) : null}

                      {d.estado === "resuelto" ? (
                        <label
                          className="w-auto btn btn-success"
                          style={{ fontSize: "12px" }}
                        >
                          <i
                            class="bi bi-check-circle me-1"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Terminado
                        </label>
                      ) : null}

                      {d.estado === "pendiente" ? (
                        <label className="w-auto btn btn-danger d-block div-crear-proceso text-nowrap d-flex me-1 px-1 py-1 align-items-center">
                          <i
                            class="bi bi-x-circle "
                            style={{ fontSize: "10px" }}
                          ></i>
                          <span
                            className="ms-1 span-crear-proceso"
                            style={{ fontSize: "12px" }}
                          >
                            Marcar como descartado
                          </span>
                          <button
                            className="d-none"
                            name="actualizar-tarea-fracaso"
                            onClick={(event) => onClick(event, d)}
                          ></button>
                        </label>
                      ) : null}

                      {d.estado === "descartada" ? (
                        <label
                          className="w-auto btn btn-danger"
                          style={{ fontSize: "12px" }}
                        >
                          <i
                            class="bi bi-x-circle me-1"
                            style={{ fontSize: "10px" }}
                          ></i>
                          Descartado
                        </label>
                      ) : null}
                    </div>
                  </label>
                ),
                start: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                end: new Date(d.fecha_vencimiento + " " + d.hora_contacto),
                priority: d.prioridad,
                status: d.estado,
              };
            })
          );
        }
        break;
    }
  };

  useEffect(() => {
    if (!data) {
      let fecha = new Date();
      let { start, end } = generateDateRange(fecha, fecha.getDate());
      start = format(start, "yyyy-MM-dd").toString();
      end = format(end, "yyyy-MM-dd").toString();

      let filter = {
        ...params,
        ["filter[intervalo_fecha_vencimiento]"]: `${start},${end}`,
      };

      fetch(filter);
    }
  }, []);

  useEffect(() => {
    const load = async () => {
      const response = await clienteAxios.get("/users");
      if (response.status == 200 || response.status == 201) {
        setUsuarios(response.data.usuarios);
      }
    };
    load();
  }, []);

  if (!data) {
    return "";
  }

  return (
    <>
      <Calendario
        onClick={onClick}
        data={data}
        navigateCalendar={navigateCalendar}
        viewCalendar={viewCalendar}
        date={date}
        onChange={onChange}
        usuarios={usuarios}
      />
      <Modal
        title={"Tarea Nº " + formT.id}
        id={"actualizar-tarea"}
        width={"900px"}
        onClose={{
          id: "btn-close-modal",
          name: "close",
          function: onClick,
        }}
        onClick={{
          title: "GUARDAR SEGUIMIENTO",
          name: "actualizar-tarea",
          function: () => {
            onClick({
              target: { name: "actualizar-tarea" },
            });
          },
        }}
        children={
          formT.id ? (
            <Tarea
              usuario={usuario}
              form={formT}
              setForm={setFormT}
              onClick={onClick}
              seguimiento={seguimiento}
              setSeguimiento={setSeguimiento}
            />
          ) : null
        }
      />
    </>
  );
};

export default CalendarioController;
