import { format } from "date-fns";
import { memo, useEffect, useState } from "react";

const autocomplete = {
  top: "60px",
  zIndex: "2",
};

const Tarea = ({
  usuario,
  form,
  setForm,
  onClick,
  seguimiento,
  setSeguimiento,
}) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "fecha_vencimiento":
      case "comentarios":
        {
          setSeguimiento({
            ...seguimiento,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "asunto":
      case "descripcion":
      case "prioridad":
      case "hora_contacto":
      case "estado":
      case "subTipo":
      case "resultado":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "tipo":
        {
          setForm({
            ...form,
            [e.target.name]: e.target.value,
            tipo_id: tipo.filter((t, index) => t.name == e.target.value)[0].id,
          });
        }
        break;
    }
  };

  const tipo = [
    {
      id: 1,
      name: "Intimación | Teléfono",
    },
    {
      id: 2,
      name: "Intimación | Carta",
    },
    {
      id: 3,
      name: "Intimación | Mensaje Whatsapp",
    },

    {
      id: 4,
      name: "Búsqueda | Trabajo",
    },
    {
      id: 5,
      name: "Búsqueda | Bienes",
    },
    {
      id: 6,
      name: "Búsqueda | Mensaje Varios",
    },

    {
      id: 7,
      name: "Análisis | Varios",
    },
    {
      id: 8,
      name: "Análisis | Juicio",
    },
    {
      id: 9,
      name: "Análisis | Desistido",
    },
  ];

  console.log(form);
  
  return (
    <div class="body p-4">
      <div className="d-flex">
        <div class="mb-3 w-100 fw-bold fs-5">
          Vencimiento:
          <span className="fw-normal ms-1 d-block">
            {form.fecha_vencimiento}
          </span>
        </div>
        <div class="mb-3 w-100 fw-bold fs-5">
          Asignado:
          <span className="fw-normal ms-1 d-block">
            {form.usuario_asignado.lastname + " " + form.usuario_asignado.name}
          </span>
        </div>
        <div class="mb-3 w-100 fw-bold fs-5">
          Estado:
          <span className="fw-normal ms-1 d-block">
            {form.estado}
            {form.estado == "resuelto" ? (
              <i class="ms-2 bi bi-check-square-fill text-success fs-3"></i>
            ) : form.estado == "descartada" ? (
              <i class="ms-2 bi bi-x-square-fill text-danger fs-3"></i>
            ) : (
              ""
            )}
          </span>
        </div>
      </div>

      <div className="d-flex">
        <div class="mb-3 w-100 fw-bold fs-5 d-block">
          Asunto
          <span className="fw-normal ms-1 d-block">{form.asunto}</span>
        </div>
        <div class="mb-3 w-100 fw-bold fs-5">
          Tipo
          <span className="fw-normal ms-1 d-block">
            {form.tipo.split("|")[0]}
          </span>
        </div>
        <div class="mb-3 w-100 fw-bold fs-5">
          SubTipo
          <span className="fw-normal ms-1 d-block">
            {form.tipo.split("|")[1]}
          </span>
        </div>
      </div>

      <div className="mb-3">
        <textarea
          type="text"
          class="form-control fs-5 py-1 bg-white"
          style={{ borderRadius: "20px" }}
          name="descripcion"
          onChange={(event) => onChange(event)}
          value={form.descripcion}
          disabled
        ></textarea>
      </div>

      <div class="mb-3 w-100">
      <label
            class="form-label fw-bold text-nowrap me-2 fs-5 d-block"
            style={{ width: "200px" }}
          >
            Resultado
          </label>
          <span className="">
            Si marca el resultado de la tarea, esta se cambiara su estado
            automaticamente a "Terminado".
          </span>
          <select
            class="form-select fs-4 py-1"
            style={{ borderRadius: "20px" }}
            aria-label="Default select example"
            name="resultado"
            onChange={(event) => onChange(event)}
          >
            {form.resultado == "" || !form.resultado ? (
              <option selected></option>
            ) : (
              ""
            )}
            {["Exito", "Fracaso"].map((res, index) => {
              if (res == "Exito" && form.resultado == 1 || res == "Fracaso" && form.resultado == 0) {
                return <option selected>{res}</option>;
              }
              return <option value={res}>{res}</option>;
            })}
          </select>
      </div>

      <div className="row">
        <label htmlFor="">Nuevo seguimiento</label>
        <div className="col-md-12 border p-3">
          <div class="mb-3 w-100">
            <label class="form-label fw-bold fs-5">Vencimiento</label>
            <input
              type="date"
              class="form-control fs-5 py-1"
              style={{ borderRadius: "20px" }}
              name="fecha_vencimiento"
              onChange={(event) => onChange(event)}
              defaultValue={seguimiento.fecha_vencimiento}
            />
          </div>

          <div className="mb-3 w-100">
            <label className="form-label fw-bold fs-5">Comentario</label>
            <textarea
              type="text"
              className="form-control fs-5 py-1"
              style={{ borderRadius: "20px" }}
              name="comentarios"
              onChange={(event) => onChange(event)}
              value={seguimiento.comentarios}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tarea;
